<template>
  <v-container class="px-0">
    <section class="col">
      <div class="col pa-0">
        <div class="order__title">
          <p>{{ $t('ORDER_COMPLEATE.thanksTitle') }}</p>
        </div>
        <div class="go_to_order">
          <BaseButton
            :title="$t('ORDER_COMPLEATE.title')"
            @click="handleClick"
            style="width: 300px; "
          />
        </div>
        <!-- <div class="order__detail">
          <div class="order__number">
            <p class="mb-2">Номер заказа</p>
            <span>{{ savedOrder?.id }}</span>
          </div>
          <div class="order__data">
            <p class="mb-2">Дата:</p>
            <span>{{ date }}</span>
          </div>
          <div class="order__email">
            <p class="mb-2">E-mail:</p>
            <span>{{ user.email }}</span>
          </div>
          <div class="order__total">
            <p class="mb-2">Всего:</p>
            <span>{{ savedOrder.sum }}</span>
          </div>
        </div>
      </div>
      <div class="order__content">
        <div class="order__inner">
          <h2>Информация о заказе</h2>
          <v-expansion-panels class="mb-1" flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="payment__product mb-0">
                <span>Product</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <template v-if="items?.length">
                  <div v-for="item in items" :key="item?.id" class="cart__list">
                    <template v-if="item.price_type === 'sole'">
                      <div
                        v-for="(sole, index) in item.soles"
                        :key="sole?.id"
                        class="cart__item mb-5"
                      >
                        <div
                          class="cart__item-container cart__item-container--top"
                        >
                          <div>
                            <div class="item-container__title">
                              {{ $t('PRODUCT_DESCRIPTION.table.image') }}
                            </div>
                            <div
                              class="pa-4"
                              style="max-height: 199px; height: 100%"
                            >
                              <img
                                style="width: 100%; height: 100%; object-fit: contain"
                                :src="item && baseUrl + item?.photo"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div class="item-container__title">
                              {{ $t('PRODUCT_DESCRIPTION.table.details') }}
                            </div>
                            <div class="pa-4 d-flex flex-column">
                              <span style="font-weight: 700" class="mb-2">
                                {{ item?.name[activeLang.language] }}
                              </span>
                              <span class="mb-2">
                                <span style="font-weight: 700">Подошва: </span>
                                {{ sole?.name }}
                              </span>
                              <div>
                                <span style="font-weight: 700"
                                  >{{ $t('PRODUCT_DESCRIPTION.branding') }}:
                                </span>
                                <ul class="pa-0 ma-0">
                                  <li
                                    v-for="(brand,
                                    index) in sole.selected_brands"
                                    :key="brand?.id"
                                  >
                                    <span>{{ index + 1 }}) </span>
                                    <span
                                      >{{ brand?.name }} =
                                      {{ brand?.price }}$</span
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="item-container__inner">
                            <div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.price') }}
                              </div>
                              <div
                                v-for="(size, index) in item?.sizes?.filter(
                                  (size) => size?.soleId === sole?.id
                                )"
                                :key="index"
                                :style="
                                  index !==
                                  item?.sizes?.filter(
                                    (size) => size?.soleId === sole?.id
                                  ) -
                                    1
                                    ? 'border-bottom: 1px solid #f5f5f5;'
                                    : ''
                                "
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700"
                                >
                                  {{
                                    (size?.price && size?.price) ||
                                      'not defined'
                                  }}$
                                </div>
                              </div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.pre_count') }}
                              </div>
                            </div>
                            <div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.color') }}
                              </div>
                              <div
                                v-for="(size, index) in item?.sizes?.filter(
                                  (size) => size.soleId === sole?.id
                                )"
                                :key="index"
                                :style="
                                  index !==
                                  item?.sizes?.filter(
                                    (size) => size.soleId === sole?.id
                                  )?.length -
                                    1
                                    ? 'border-bottom: 1px solid #f5f5f5;'
                                    : ''
                                "
                                class="position-relative item-container__content"
                              >
                                <div class="d-flex align-center justify-center">
                                  <div
                                    class="item-container__content-color"
                                    :style="{
                                      backgroundColor: item?.colors?.filter(
                                        (color) => size.colorId === color?.id
                                      )[0].css_code,
                                    }"
                                  ></div>
                                </div>
                              </div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.color') }}
                              </div>
                              <div
                                :style="
                                  index !==
                                  item?.sizes?.filter(
                                    (size) => size.soleId === sole?.id
                                  )?.length -
                                    1
                                    ? 'border-bottom: 1px solid #f5f5f5;'
                                    : ''
                                "
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700; font-size: 20px"
                                >
                                  {{
                                    item?.colors?.filter(
                                      (color) => color.soleId === sole?.id
                                    )?.length
                                  }}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.size') }}
                              </div>
                              <div
                                v-for="(size, index) in item?.sizes?.filter(
                                  (size) => size.soleId === sole?.id
                                )"
                                :key="index"
                                :style="
                                  index !==
                                  item?.sizes?.filter(
                                    (size) => size.soleId === sole?.id
                                  )?.length -
                                    1
                                    ? 'border-bottom: 1px solid #f5f5f5;'
                                    : ''
                                "
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700; font-size: 20px"
                                >
                                  {{ size?.name }}
                                </div>
                              </div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.size') }}
                              </div>
                              <div
                                :style="
                                  index !==
                                  item?.sizes?.filter(
                                    (size) => size.soleId === sole?.id
                                  )?.length -
                                    1
                                    ? 'border-bottom: 1px solid #f5f5f5;'
                                    : ''
                                "
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700; font-size: 20px"
                                >
                                  {{
                                    item?.sizes?.filter(
                                      (size) => size.soleId === sole?.id
                                    )?.length
                                  }}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.count') }}
                              </div>
                              <div
                                v-for="(size, index) in item?.sizes?.filter(
                                  (size) => size.soleId === sole?.id
                                )"
                                :key="index"
                                :style="
                                  index !==
                                  item?.sizes?.filter(
                                    (size) => size.soleId === sole?.id
                                  )?.length -
                                    1
                                    ? 'border-bottom: 1px solid #f5f5f5;'
                                    : ''
                                "
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center pa-2"
                                >
                                  <button
                                    class="content__button content__button--decrease"
                                    @click="size?.count > 1 ? size.count-- : ''"
                                  >
                                    -
                                  </button>
                                  <span
                                    class="text-center px-3 py-1 mx-1"
                                    style="border-radius: 5px; border: 1px solid #cecece"
                                  >
                                    {{ size?.count }}
                                  </span>
                                  <button
                                    class="content__button content__button--increase"
                                    @click="size.count++"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.count') }}
                              </div>
                              <div
                                :style="
                                  index !==
                                  item?.sizes?.filter(
                                    (size) => size.soleId === sole?.id
                                  )?.length -
                                    1
                                    ? 'border-bottom: 1px solid #f5f5f5;'
                                    : ''
                                "
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700; font-size: 20px"
                                >
                                  {{
                                    item?.sizes
                                      .filter(
                                        (size) => size.soleId === sole?.id
                                      )
                                      .reduce(
                                        (sum, curr) => (sum += curr.count),
                                        0
                                      )
                                  }}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.total') }}
                              </div>
                              <div
                                v-for="(size, index) in item?.sizes?.filter(
                                  (size) => size.soleId === sole?.id
                                )"
                                :key="index"
                                class="position-relative item-container__content"
                              >
                                <div
                                  :style="
                                    index !==
                                    item?.sizes?.filter(
                                      (size) => size.soleId === sole?.id
                                    )?.length -
                                      1
                                      ? 'border-bottom: 1px solid #f5f5f5;'
                                      : ''
                                  "
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700"
                                >
                                  $ {{ size?.price * size?.count }}
                                  <div style="position: absolute; right: 0">
                                    <v-btn
                                      color="#c3c3c3"
                                      @click="
                                        removeItem({
                                          type: item?.price_type,
                                          itemId: item?.id,
                                          sizeId: size?.id,
                                          colorId: size?.colorId,
                                          soleId: size?.soleId,
                                        })
                                      "
                                      icon
                                    >
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                  </div>
                                </div>
                              </div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.total') }}
                              </div>
                              <div
                                class="position-relative item-container__content"
                              >
                                <div
                                  :style="
                                    index !==
                                    item.sizes.filter(
                                      (size) => size.soleId === sole?.id
                                    )?.length -
                                      1
                                      ? 'border-bottom: 1px solid #f5f5f5;'
                                      : ''
                                  "
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700"
                                >
                                  $
                                  {{
                                    item.sizes
                                      ?.filter(
                                        (size) => size.soleId === sole?.id
                                      )
                                      .reduce(
                                        (sum, curr) =>
                                          (sum += curr?.count * curr?.price),
                                        0
                                      )
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="item-container__castomization-btn d-flex justify-center align-center"
                            style="grid-column: span 2"
                          >
                            <div style="width: 70%">
                              <BaseButton
                                title="Брендирование"
                                @click="openCustomizationDialog(item, sole)"
                              />
                            </div>
                          </div>
                          <div class="item-container__castomization-logo pa-4">
                            <div
                              class="d-flex justify-center align-center"
                              style="font-weight: 700"
                            >
                              {{ $t('PRODUCT_DESCRIPTION.notes') }}
                            </div>
                            <div class="d-flex justify-center align-center">
                              <img
                                style="max-width: 100%; max-height: 200px"
                                :src="item?.logo?.preview"
                                alt="logo preview"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="cart__item mb-5">
                        <div
                          class="cart__item-container cart__item-container--top"
                        >
                          <div>
                            <div class="item-container__title">
                              {{ $t('PRODUCT_DESCRIPTION.table.image') }}
                            </div>
                            <div
                              class="pa-4"
                              style="max-height: 199px; height: 100%"
                            >
                              <img
                                style="width: 100%; height: 100%; object-fit: contain"
                                :src="item && baseUrl + item?.photo"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div class="item-container__title">
                              {{ $t('PRODUCT_DESCRIPTION.table.details') }}
                            </div>
                            <div class="pa-4">
                              <span
                                style="font-weight: 700"
                                class="mb-2 d-block"
                              >
                                {{ item?.name[activeLang.language] }}
                              </span>
                              <div>
                                <span style="font-weight: 700"
                                  >{{ $t('PRODUCT_DESCRIPTION.branding') }}:
                                </span>
                                <ul class="pa-0 ma-0">
                                  <li
                                    v-for="(brand,
                                    index) in item?.selected_brands"
                                    :key="brand?.id"
                                  >
                                    <span>{{ index + 1 }}) </span>
                                    <span
                                      >{{ brand?.name }} =
                                      {{ brand?.price }}$</span
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="item-container__inner">
                            <div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.price') }}
                              </div>
                              <div
                                v-for="(size, index) in item?.sizes"
                                :key="index"
                                :style="
                                  index !== item?.sizes?.length - 1
                                    ? 'border-bottom: 1px solid #f5f5f5;'
                                    : ''
                                "
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700"
                                >
                                  {{ size?.price || 'not defined' }}$
                                </div>
                              </div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.pre_count') }}
                              </div>
                              <div
                                class="position-relative item-container__content"
                              >
                              </div>
                            </div>
                            <div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.color') }}
                              </div>
                              <div
                                v-for="(size, index) in item?.sizes"
                                :key="index"
                                :style="
                                  index !== item?.sizes?.length - 1
                                    ? 'border-bottom: 1px solid #f5f5f5;'
                                    : ''
                                "
                                class="position-relative item-container__content"
                              >
                                <div class="d-flex align-center justify-center">
                                  <div
                                    class="item-container__content-color"
                                    :style="{
                                      backgroundColor: item?.colors?.find(
                                        (color) => color?.id === size.colorId
                                      ).css_code,
                                    }"
                                  ></div>
                                </div>
                              </div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.color') }}
                              </div>
                              <div
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700; font-size: 20px"
                                >
                                  {{ item?.colors?.length }}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="item-container__title">{{
                                $t('PRODUCT_DESCRIPTION.table.size')
                              }}</div>
                              <div
                                v-for="(size, index) in item?.sizes"
                                :key="index"
                                :style="
                                  index !== item?.sizes?.length - 1
                                    ? 'border-bottom: 1px solid #f5f5f5;'
                                    : ''
                                "
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700; font-size: 20px"
                                >
                                  {{ size?.name }}
                                </div>
                              </div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.size') }}
                              </div>
                              <div
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700; font-size: 20px"
                                >
                                  {{ item?.sizes?.length }}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.count') }}
                              </div>
                              <div
                                v-for="(size, index) in item?.sizes"
                                :key="index"
                                :style="
                                  index !== item?.sizes?.length - 1
                                    ? 'border-bottom: 1px solid #f5f5f5;'
                                    : ''
                                "
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center pa-2"
                                >
                                  <button
                                    class="content__button content__button--decrease"
                                    @click="size?.count > 1 ? size.count-- : ''"
                                  >
                                    -
                                  </button>
                                  <span
                                    class="text-center px-3 py-1 mx-1"
                                    style="border-radius: 5px; border: 1px solid #cecece"
                                  >
                                    {{ size?.count }}
                                  </span>
                                  <button
                                    class="content__button content__button--increase"
                                    @click="size.count++"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.count') }}
                              </div>
                              <div
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700; font-size: 20px"
                                >
                                  {{
                                    item?.sizes?.reduce(
                                      (sum, curr) => (sum += curr?.count),
                                      0
                                    )
                                  }}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.total') }}
                              </div>
                              <div
                                v-for="(size, index) in item?.sizes"
                                :key="index"
                                class="position-relative item-container__content"
                              >
                                <div
                                  :style="
                                    index !== item.sizes?.length - 1
                                      ? 'border-bottom: 1px solid #f5f5f5;'
                                      : ''
                                  "
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700"
                                >
                                  $ {{ size?.price * size?.count }}
                                  <div style="position: absolute; right: 0">
                                    <v-btn
                                      color="#c3c3c3"
                                      icon
                                      @click="
                                        removeItem({
                                          type: item.price_type,
                                          itemId: item?.id,
                                          sizeId: size?.id,
                                          colorId: size?.colorId,
                                        })
                                      "
                                    >
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                  </div>
                                </div>
                              </div>
                              <div class="item-container__title">
                                {{ $t('PRODUCT_DESCRIPTION.table.total') }}
                              </div>
                              <div
                                class="position-relative item-container__content"
                              >
                                <div
                                  class="d-flex align-center justify-center"
                                  style="font-weight: 700"
                                >
                                  $
                                  {{ totalPrice.fullPrice }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="cart__item-container cart__item-container--bottom"
                        >
                          <div
                            class="item-container__castomization-btn d-flex justify-center align-center"
                          >
                            <div style="width: 70%">
                              <BaseButton
                                :title="$t('PRODUCT_DESCRIPTION.branding')"
                                @click="openCustomizationDialog(item)"
                              />
                            </div>
                          </div>
                          <div class="item-container__castomization-logo pa-4">
                            <div
                              class="d-flex justify-center align-center"
                              style="font-weight: 700"
                            >
                              {{ $t('PRODUCT_DESCRIPTION.notes') }}
                            </div>
                            <div
                              v-if="item.logo.logo"
                              class="d-flex justify-center align-center"
                            >
                              <img
                                style="max-width: 100%; max-height: 200px"
                                :src="getLogoPreview(item.logo.logo)"
                                alt="logo preview"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <v-dialog v-model="castomizationDialog" :max-width="700">
                  <v-card>
                    <SelectedBrands
                      :logo="editing_item && editing_item.logo"
                      :brands="my_brands"
                      :selected_brands="selected_brands"
                      @onPositionChange.stop="onPositionChange"
                      @onLogoChange="editing_item.logo.logo = $event"
                    />
                  </v-card>
                </v-dialog>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="payment__total-wrapper">
           
            <div v-if="items?.length" class="col-sm-12 px-0">
              <ul class="total_order pa-0 d-flex justify-space-between">
                <li class="btn_item">
                  <p class="btn_total">
                    <span class="btn_product_text mr-4"
                      >{{
                        $t('PRODUCT_DESCRIPTION.second_table.totalCount')
                      }}: </span
                    ><span class="btn_product_sum">{{
                      totalItemsQuantity
                    }}</span>
                  </p>
                </li>
                <li class="btn_item">
                  <p class="btn_total">
                    <span class="btn_product_text mr-4"
                      >{{ $t('PRODUCT_DESCRIPTION.second_table.sum') }}: </span
                    ><span class="btn_product_sum">{{
                      totalPrice.fullPrice
                    }}</span>
                  </p>
                </li>
                <li class="btn_item">
                  <p class="btn_total">
                    <span class="btn_product_text mr-4"
                      >{{
                        $t('PRODUCT_DESCRIPTION.second_table.discount')
                      }}: </span
                    ><span class="btn_product_sum">-0%</span>
                  </p>
                </li>
                <li class="btn_item">
                  <p class="btn_total">
                    <span class="btn_product_text mr-4"
                      >{{
                        $t('PRODUCT_DESCRIPTION.second_table.saving')
                      }}: </span
                    ><span class="btn_product_sum">{{ 0 }}</span>
                  </p>
                </li>
                <li class="btn_item">
                  <p class="t_order_total btn_total">
                    <span class="btn_product_text mr-4"
                      >{{
                        $t('PRODUCT_DESCRIPTION.second_table.total')
                      }}: </span
                    ><span class="btn_product_sum">{{
                      totalPrice.fullPrice
                    }}</span>
                  </p>
                </li>
                <li class="btn_item">
                  <p class="t_order_total btn_total">
                    <span class="btn_product_text mr-4"
                      >{{ $t('PRODUCT_DESCRIPTION.second_table.saved') }}:
                    </span>
                    <span class="btn_product_sum">
                      {{
                        notifications?.reduce(
                          (sum, curr) => (sum += curr.saving),
                          0
                        )
                      }}
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="order__payment-total">
            <span>Total</span>
            <span>${{ totalPrice.fullPrice }}</span>
          </div>
          <div class="row order__address ma-0">
            <div class="col-sm-3 payment__terminal">
              <h3>{{ $t('ORDER_HISTORY.payment_terminal') }}</h3>
              <p>
                Покупатель:
                {{ user.first_name }} {{ user.last_name }}
              </p>
              <p>
                Email:
                {{ user.email }}
              </p>
            </div>
            <div class="col-sm-3 delivery__address">
              <h3>{{ $t('ORDER_HISTORY.delivery_address') }}:</h3>
              <p>
                Адрес:
                {{ JSON.parse(savedOrder?.delivery).address }}
              </p>
              <p>
                Страна:
                {{ JSON.parse(savedOrder?.delivery).country }}
              </p>
              <p>
                Почтовый индекс:
                {{ JSON.parse(savedOrder?.delivery).mailIndex }}
              </p>
            </div>
            <div class="col-sm-2 pa-0"></div>
          </div> -->
        <!-- </div> -->
      </div>
    </section>
  </v-container>
</template>
<!-- <div class="col-12 col-sm-4">
  <BaseButton
    :title="$t('ORDER_HISTORY.invoice')"
    disabled
    @click="redirectToSpecificRoute"
  />
</div> -->

<script>
import { createNamespacedHelpers } from 'vuex'
import BaseButton from '../../layout/BaseButton.vue'
// import SelectedBrands from '../../content/SelectedBrands.vue'

// import OrderItem from './OrderItem.vue'
const { mapState } = createNamespacedHelpers('auth')
const { mapState: Order_history_state } = createNamespacedHelpers(
  'orderHistory'
)
const { mapState: State_notifications } = createNamespacedHelpers(
  'notifications'
)
export default {
  name: 'AdminOrderComplete',
  components: {
    BaseButton,
    // SelectedBrands,
  },
  data() {
    return {
      items: [],
      baseUrl: '',
      activeLang: '',
      castomizationDialog: false,
      my_brands: [],
      selected_brands: [],
      editing_item: {},
      editing_sole: {},
    }
  },
  computed: {
    ...State_notifications(['notifications']),
    ...mapState(['user']),
    ...Order_history_state(['savedOrder']),
    date() {
      return (
        new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
      )
    },
    totalItemsQuantity() {
      let sum = 0
      this.items.forEach((item) => {
        sum += item?.sizes.reduce((sum, curr) => (sum += curr.count), 0)
      })
      return sum
    },
    totalPrice() {
      let sum = 0
      this.items?.forEach((item) => {
        sum += item.sizes.reduce(
          (sum, curr) => (sum += curr?.price * curr?.count),
          0
        )

        if (item.selected_brands?.length) {
          sum += item.selected_brands?.reduce(
            (sum, curr) => (sum += +curr?.price),
            0
          )
        }
      })

      return {
        fullPrice: sum,
        savingPrice: sum - (sum * 5) / 100,
        saving: (sum * 5) / 100,
      }
    },
    // saving() {
    //   return Math.floor(this.totalPrice.saving)
    // },
    // totalPriceWithSaving() {
    //   return Math.floor(this.totalPrice.savingPrice)
    // },
  },
  methods: {
    getLogoPreview(value) {
      return value && URL.createObjectURL(value)
    },
    openCustomizationDialog(item, sole) {
      this.editing_item = item
      this.editing_sole = sole
      this.castomizationDialog = true
      if (item.price_type === 'sole') {
        this.my_brands = sole.brands
        this.selected_brands = sole.selected_brands
      } else {
        this.my_brands = item?.reyestrItem.brands
        this.selected_brands = item?.selected_brands
      }
    },
    handleClick() {
      this.$router.push({
        name: 'order history',
      })
    },
  },
  mounted() {
    this.items = this.$route.query.items
    this.baseUrl = this.$store.state.auth.API_URL
    this.activeLang = this.$store.state.language
  },
}
</script>

<style scoped lang="scss">
$white: #ffffff;
$grey: #b5b5b5;
$gr: #f5f5f5;
$bl: #423f3d;
$black: #31343c;
$gr_c: #fbfbfb;
$grtx: #a4a4a4;
$green: #cfebcc;
$gr_col: #818181;
$green_c: #a3c2a0;
$bl_c: #90ace2;

@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}

.container_order {
  background-color: #fff;
}
.cart__item {
  display: grid;
}

.order__title {
  margin-bottom: 200px;
}
.go_to_order {
  text-align: end;
}
.cart__item-container {
  display: grid;
  border: 1px solid $gr;
  &--top {
    grid-template-columns: 200px 200px 1fr;
    & > div:not(:last-child) {
      border-right: 1px solid $gr;
    }
  }
  &--bottom {
    grid-template-columns: 400px 1fr;
    border-top: none;
  }
}
.item-container {
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    & > div:not(:last-child) {
      border-right: 1px solid $gr;
    }
  }
  &__title {
    border-bottom: 1px solid $gr;
    background-color: $gr;
    text-transform: uppercase;
    @include ftz(16px, 400, $bl);
    text-align: center;
  }
  &__content {
    width: 100%;
    padding-top: 100%;
    & > div {
      position: absolute;
      inset: 0;
    }
    &-color {
      width: 50%;
      height: 50%;
    }
  }
  &__castomization-btn {
    height: 100%;
    border-right: 1px solid $gr;
  }
  &__castomization-logo {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }
}
.content {
  &__button {
    color: $grey;
    flex-grow: 1;
    cursor: pointer;
    &--increase {
      background-color: $green;
    }
    &--decrease {
      background-color: $gr;
    }
  }
}
.btn_item {
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  padding: 9px 26px;
  font-size: 16px;
  color: #666666;
  .btn_product_sum {
    font-size: 24px;
    font-family: 'Roboto Condensed';
  }
  .btn_product_text {
    font-weight: 500;
  }
}
</style>
